import '@fontsource/lexend';
import * as ReactDOM from 'react-dom/client';
import { initializeFaro } from '@grafana/faro-web-sdk';

import { getFaroConfig } from '@cccom/shared/logging';

import App from './app/App';
import { environment } from './env';

export async function initializeApp() {
  if (import.meta.env.MODE === 'mockApi') {
    const { worker } = await import(`@cccom/view/mocks`);
    await worker.start();
  }

  if (import.meta.env.MODE === 'production') {
    initializeFaro(getFaroConfig(environment.FARO_ENDPOINT));
  }
}

async function main() {
  await initializeApp();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(<App />);
}

main();
