import { HStack, Text, Flex, Tooltip, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TbSettings } from 'react-icons/tb';
import { NavLink, useLocation } from 'react-router';

export function Settings({ isCollapsed }: { isCollapsed: boolean }) {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const isActive = pathname.startsWith('/settings');
  return (
    <Tooltip
      fontSize="xs"
      placement="right"
      isDisabled={!isCollapsed}
      label={t('atreview.settings.settings')}
    >
      <NavLink to="settings">
        <HStack
          spacing="4"
          _hover={{ bg: 'gray.100' }}
          bg={isActive ? 'gray.100' : 'transparent'}
          py="4"
          px="7"
          w="full"
          color="gray.500"
          justifyContent={isCollapsed ? 'center' : 'start'}
        >
          <Flex gap="4">
            <Icon as={TbSettings} fontSize="2xl" />
            <Text
              opacity={isCollapsed ? '0' : '1'}
              position={isCollapsed ? 'absolute' : 'initial'}
              transition={isCollapsed ? 'none' : 'ease-in opacity 0.3s'}
            >
              {t('atreview.settings.settings')}
            </Text>
          </Flex>
        </HStack>
      </NavLink>
    </Tooltip>
  );
}
