import { useCallback, useContext } from 'react';

import { Permissions } from '@cccom/auth/permissions-type';

import mapPermissions from './permission-mapper';
import { PermissionsContext } from './PermissionsContext';
import { CCPermissions } from './types';

export function accessNestedObject(
  keys: string[],
  obj: CCPermissions | Permissions[]
): Permissions[] {
  const key = keys.shift();
  if (!key && Array.isArray(obj)) return obj;

  if (key && !Array.isArray(obj) && key in obj) {
    return accessNestedObject(keys, obj[key]);
  }

  return [];
}

/**
 * hook for managing PermissionsContext
 */
export function usePermissions() {
  const { permissionsContext, setPermissionsContext } =
    useContext(PermissionsContext);
  const { roles, permissions, key } = permissionsContext;
  const permissionsArr = accessNestedObject(key.split('.'), permissions);

  /**
   * Check if the user has the required permissions
   * @param scopes - The permissions required to render the children.
   * @param permissions - The permissions to check against. Defaults to the permissions in the context.
   * @param every - If true, all permissions must be present. If false, any permission will suffice.
   * @returns True if the user has the required permissions, otherwise false.
   */
  const hasPermission = useCallback(
    ({
      scopes,
      permissions: _permissions = permissionsArr,
      every = false,
    }: {
      scopes: Permissions[];
      permissions?: Permissions[];
      every?: boolean;
    }) => {
      if (scopes.length === 0) return true;

      const combinedPermissions = [..._permissions, ...roles];
      if (every) {
        return scopes.every((scope) => combinedPermissions.includes(scope));
      }

      return combinedPermissions.some((permission) =>
        scopes.includes(permission)
      );
    },
    [permissionsArr, roles]
  );

  const setContext = useCallback(
    (_permissions: string[], _key: string) => {
      const mappedPerms = mapPermissions(_permissions);

      setPermissionsContext({
        roles,
        permissions: { ...permissions, ...mappedPerms },
        key: _key,
      });
    },
    [roles, permissions, setPermissionsContext]
  );

  return {
    hasPermission,
    setPermissionsContext: setContext,
  };
}
