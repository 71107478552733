import { Button, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { ElementType } from 'react';
import { NavLink } from 'react-router';

import { AppIds, AppMenu } from '@cccom/config/app-type';

export type NavButtonProps<TApp extends AppIds> = {
  path: AppMenu[TApp];
  label: string;
  icon?: ElementType;
  isActive?: boolean;
  isCollapsed?: boolean;
};

export function NavButton<TApp extends AppIds>({
  path,
  label,
  icon,
  isActive = false,
  isCollapsed = true,
}: NavButtonProps<TApp>) {
  return (
    <Tooltip
      fontSize="xs"
      placement="right"
      label={label}
      isDisabled={!isCollapsed}
    >
      <Button
        h="12"
        w={isCollapsed ? '12' : 'full'}
        variant="ghost"
        fontSize="xs"
        overflow="hidden"
        borderRadius="50px"
        color="blackAlpha.700"
        as={NavLink}
        to={path}
        justifyContent={isCollapsed ? 'center' : 'left'}
        transition={isCollapsed ? '0.15s' : '0.3s'}
        aria-current={isActive ? 'page' : false}
        _hover={{ bg: 'gray.100' }}
        _active={{ bg: 'brand.25', color: 'brand.500' }}
        _activeLink={{ bg: 'brand.25', color: 'brand.500' }}
      >
        <HStack spacing="4" justifyContent="center">
          {icon && <Icon data-testid="menu-icon" as={icon} boxSize="5" />}
          <Text
            opacity={isCollapsed ? '0' : '1'}
            position={isCollapsed ? 'absolute' : 'initial'}
            transition={isCollapsed ? 'none' : 'ease-in opacity 0.3s'}
          >
            {label}
          </Text>
        </HStack>
      </Button>
    </Tooltip>
  );
}
