/* eslint-disable no-nested-ternary */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useNavigate,
} from 'react-router';

import { PermissionsProvider } from '@cccom/auth/permissions';
import { AppList } from '@cccom/home/app-list';
import { CCErrorPage, SharedLayout, TopbarProps } from '@cccom/shared/ui';

import { environment as env } from '../env';
import useAutoRedirect from './use-auto-redirect';

function HomeLayout() {
  const navigate = useNavigate();
  useAutoRedirect();

  const topbar: TopbarProps = {
    logoSrc: 'images/CClogo.svg',
    appName: '',
    onClickLogo: () => navigate('/'),
  };

  return (
    <SharedLayout topbar={topbar}>
      <Outlet />
    </SharedLayout>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <PermissionsProvider>
          <HomeLayout />
        </PermissionsProvider>
      }
      errorElement={<CCErrorPage type="PAGE_ERROR" />}
    >
      <Route path="" element={<Navigate to="/apps" replace />} />
      <Route path="apps" element={<AppList />} errorElement={<CCErrorPage />} />
      <Route path="*" element={<CCErrorPage type="404" />} />
    </Route>
  ),
  {
    basename: env.production ? env.APP?.path : '',
  }
);
