import { Box, Divider, Flex, Icon } from '@chakra-ui/react';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { useLocation } from 'react-router';

import { AppIds } from '@cccom/config/app-type';

import HelpCard from '../help-card/HelpCard';
import { NavButton, NavButtonProps } from './NavButton';
import { ProfileMenu } from './ProfileMenu';
import { Settings } from './Settings';

function CollapseBtn({
  isCollapsed,
  setIsCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Box
      padding="1"
      margin="-1"
      data-testid="collapse-btn"
      as="button"
      display="flex"
      position="absolute"
      right="-10px"
      top="calc(var(--chakra-space-7) + var(--topbar-height))"
      borderRadius="full"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="gray.400"
      outline="2px solid transparent"
      bg="white"
      color="blackAlpha.700"
      cursor="pointer"
      transition="ease-in-out 0.2s"
      zIndex="1"
      _hover={{
        padding: 2,
        margin: -2,
        bg: 'brand.25',
        color: 'brand.500',
      }}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Icon as={isCollapsed ? TbChevronRight : TbChevronLeft} />
    </Box>
  );
}

export type SidebarProps<TApp extends AppIds> = {
  menuItems: NavButtonProps<TApp>[];
  profileMenuChildren?: ReactNode;
  profileMenuItems?: ReactNode[];
  showSettings?: boolean;
};

export function Sidebar<TApp extends AppIds>({
  menuItems,
  profileMenuChildren,
  profileMenuItems,
  showSettings,
}: SidebarProps<TApp>) {
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Flex
      zIndex="1"
      height="full"
      flexDir="column"
      position="relative"
      borderRight="0.5px solid"
      borderColor="gray.200"
      justifyContent="space-between"
    >
      <Box mt="var(--topbar-height)">
        <CollapseBtn
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />

        <Box
          as="nav"
          width={isCollapsed ? '20' : '60'}
          transition={isCollapsed ? '0.15s' : '0.3s'}
        >
          <Flex py="7" px="4" direction="column" gap="3">
            {menuItems.map(({ path, label, icon, ...rest }) => {
              return (
                <NavButton
                  key={path}
                  path={path}
                  label={label}
                  icon={icon}
                  isActive={pathname.startsWith(`/${path}`)}
                  isCollapsed={isCollapsed}
                  {...rest}
                />
              );
            })}
          </Flex>
        </Box>
      </Box>

      <Flex zIndex="1" direction="column" bottom="0" mt="4">
        {showSettings && <Settings isCollapsed={isCollapsed} />}
        <HelpCard isCollapsed={isCollapsed} />
        {!isCollapsed && <Divider />}
        <ProfileMenu isCollapsed={isCollapsed} menuItems={profileMenuItems}>
          {profileMenuChildren}
        </ProfileMenu>
      </Flex>
    </Flex>
  );
}
