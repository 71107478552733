import {
  AbsoluteCenter,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router';

import { APPS } from '@cccom/config/app-config';
import { useEnvironment } from '@cccom/config/env';
import { log } from '@cccom/shared/logging';

import InlineSvgNoInternet from './InlineSvgNoInternet';

export type CCErrorPageProps = {
  type?:
    | '404'
    | 'PAGE_ERROR'
    | 'NO_INTERNET'
    | 'UNAUTHORIZED'
    | 'SOMETHING_WENT_WRONG';
};

export type ErrorLabelType = {
  headerText?: string;
  mainText?: string;
  buttonText?: string;
  canNavigate?: boolean;
};

function SomethingWentWrong() {
  const [t] = useTranslation();

  return (
    <Box minW="300px" color="gray.600" m="8">
      <AbsoluteCenter>
        <Flex direction="column" justify="center" align="center" gap="4">
          <Image
            src="images/error-boundary.png"
            alt="something-went-wrong"
            maxHeight="100"
          />
          <Heading textAlign="left" fontSize="2xl" lineHeight="auto">
            {t('common.errors.something_went_wrong')}
          </Heading>
          <Text textAlign="center" fontSize="16" w="400px" p="0">
            <Trans i18nKey="common.errors.something_went_wrong_blurb" />
          </Text>
        </Flex>
      </AbsoluteCenter>
    </Box>
  );
}

export function CCErrorPage({ type }: CCErrorPageProps) {
  const error = useRouteError();
  const env = useEnvironment();
  const [t] = useTranslation();

  useEffect(() => {
    if (error) log.error('Unexpected Error:', error);
  }, [error]);

  let errorLabels: ErrorLabelType;

  switch (type) {
    case '404':
      errorLabels = {
        headerText: t('common.errors.page_not_found'),
        mainText: t('common.errors.sorry_page_moved'),
        buttonText: t('common.actions.404_button'),
        canNavigate: true,
      };
      break;
    case 'PAGE_ERROR':
      errorLabels = {
        headerText: t('common.errors.something_went_wrong'),
        mainText: t('common.errors.technical_difficulties'),
        buttonText: t('common.actions.try_again'),
        canNavigate: false,
      };
      break;
    case 'NO_INTERNET':
      errorLabels = {
        headerText: t('common.errors.no_internet_connection'),
        mainText: t('common.errors.no_internet_msg'),
        buttonText: t('common.actions.try_again'),
        canNavigate: false,
      };
      break;
    case 'UNAUTHORIZED':
      errorLabels = {
        headerText: t('common.errors.unauthorized'),
        buttonText: t('common.actions.404_button'),
        canNavigate: true,
      };
      break;
    case 'SOMETHING_WENT_WRONG': {
      return <SomethingWentWrong />;
    }
    default:
      errorLabels = {
        headerText: t('common.errors.something_went_wrong'),
        mainText: t('common.errors.technical_difficulties'),
        buttonText: t('common.actions.404_button'),
        canNavigate: true,
      };
      break;
  }

  const { headerText, mainText, buttonText, canNavigate } = errorLabels;

  return (
    <Container p="4" pt="24">
      <Flex justify="center" alignItems="center">
        <VStack alignItems="flex-start" spacing="4" maxW="50%" pr="20">
          <Heading textAlign="left" fontSize="6xl" color="blue.900">
            {headerText}
          </Heading>
          <Text textAlign="left" fontSize="20" color="gray.600" w={400} py={8}>
            {mainText}
          </Text>

          {buttonText &&
            (canNavigate && env.APP?.path ? (
              <Link href={APPS.getUrl(env, env.APP)}>
                <Button colorScheme="brand">{buttonText}</Button>
              </Link>
            ) : (
              type !== '404' && (
                <Button
                  colorScheme="brand"
                  onClick={() => window.location.reload()}
                >
                  {buttonText}
                </Button>
              )
            ))}
        </VStack>
        <VStack alignItems="center" justifyContent="center" p="4">
          {type === 'NO_INTERNET' ? (
            <InlineSvgNoInternet />
          ) : (
            <Image
              src={env.APP?.errorLogo ?? 'images/cc-404-graphic.svg'}
              alt="logo-graphic"
            />
          )}
        </VStack>
      </Flex>
    </Container>
  );
}
