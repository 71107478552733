import { Button, Flex, Textarea } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { log } from '@cccom/shared/logging';
import { zCCString, zCCStringCharLimit } from '@cccom/shared/utils/common';

import { DebouncedButton } from '../debounced-button/DebouncedButton';
import { CCFormControl } from '../form-control/CCFormControl';

type CommentFormProps = {
  onSubmit: (data: CommentData) => Promise<void>;
  commentType?: 'ADD' | 'REPLY';
  maxLength?: number;
  defaultValue?: string;
  onCancel?: () => void;
};

const COMMENT_CHAR_LIMIT = 500;

const commentSchema = z.object({
  message: zCCString({ field: 'Comment' }).and(
    zCCStringCharLimit({ char_limit: COMMENT_CHAR_LIMIT })
  ),
});

type CommentData = z.infer<typeof commentSchema>;

export function CommentForm({
  onSubmit,
  maxLength = COMMENT_CHAR_LIMIT,
  commentType = 'ADD',
  defaultValue,
  onCancel,
}: CommentFormProps) {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<CommentData>({
    resolver: zodResolver(commentSchema),
    defaultValues: { message: defaultValue || '' },
    mode: 'onChange',
  });

  const handleSubmitComment = async (data: CommentData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      log.error('Failed to submit comment:', error);
    }
  };

  const cancelHandler = () => {
    reset();
    if (onCancel) onCancel();
  };

  return (
    <Flex direction="column" gap="2">
      <CCFormControl error={errors.message}>
        <Textarea
          id="comment"
          rows={4}
          maxLength={maxLength + 1}
          resize="none"
          placeholder={
            commentType === 'ADD'
              ? t('common.actions.add_a_comment')
              : t('common.actions.reply')
          }
          {...register('message')}
          data-cy="comment"
          _placeholder={{ color: 'gray.400' }}
          color="gray.500"
          isInvalid={!!errors?.message?.message}
          errorBorderColor="red.500"
        />
      </CCFormControl>

      <Flex gap="2" alignSelf="start" mb="4">
        <DebouncedButton
          size="xs"
          colorScheme="brand"
          variant="primary"
          onClick={handleSubmit(handleSubmitComment)}
          isDisabled={!isDirty || !isValid}
        >
          {t('common.actions.save_comment')}
        </DebouncedButton>
        <Button
          size="xs"
          variant="outline"
          bgColor="white"
          onClick={cancelHandler}
          isDisabled={!isDirty && !defaultValue}
        >
          {t('common.actions.cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}
